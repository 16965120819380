<script>
export let number;
</script>

<div
id="bike-container"
class="absolute bike-container">
    <div style="width: 500px">
        <p id="bike-text-1" class="font-baskervilleReg text-7xl">{number}</p>
        <p class="font-chronicaBold text-5xl">bikes</p>
    </div>
    <object class="bike absolute top-0 left-0" title="bike" data="assets/images/welcome/bike.svg" type="image/svg+xml"></object>
    <slot name="image"></slot>
</div>

<style>
.bike-container {
    width: 600px;
    height: 380px;
    top:0;
    left: 0;
    transform-origin: top left;
    /* for text */
    padding-top: 50px;
    padding-left: 426px;
    color: #4282AE;
}
.bike {
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
}





/* small */
@media screen and (min-width: 0px) {

}
/* medium */
@media screen and (min-width: 768px) {

}
/* large */
@media screen and (min-width: 1024px) {

}
</style>