<script>
export let hand;
export let addClass;
export let addStyle;
</script>

<!-- <object
    class="absolute z-20 hand hand-{hand} {addClass ? addClass : ''}"
    style="{addStyle ? addStyle : ''}"
    id="hand-{hand}"
    data="assets/images/welcome/hands/Hand-{hand}.svg"
    title="hand"
    type="image/svg+xml"
></object> -->
<img
    class="absolute z-20 max-w-none hand hand-{hand} {addClass ? addClass : ''}"
    style="{addStyle ? addStyle : ''}"
    id="hand-{hand}"
    src="assets/images/welcome/hands/Hand-{hand}.svg"
    alt='hand'
/>

<style>

</style>