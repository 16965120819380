import { gsap } from 'gsap';
import lawData from './../../data/discoverSliderData/LawsPassed.json';
let lawObj;
let finalLawData;
let prevLawValue;
export function animateLaws(a) {
    lawObj = lawData.find(item => item.year === a);
    let currLawValue = lawObj.LawsPassed;
    finalLawData = gsap.to('#bookNumber', {
        textContent: lawObj.LawsPassed,
        duration: 0.5,
        ease: 'power1.inOut',
        snap: { textContent: 1 },
    })
    if (currLawValue !== '0') {
        gsap.to('#bookSil', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#book', {
            opacity: 1,
            duration: 1,
        })
    }
    if (currLawValue === '0') {
        gsap.to('#bookSil', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#book', {
            opacity: 0,
            duration: 1,
        })
    }
    if (prevLawValue !== currLawValue) {
        gsap.fromTo('#hammer', {
            rotation: '0',
        }, {
            rotation: '304_ccw',
            yoyo: true,
            repeat: 3,
        })
    }
    prevLawValue = currLawValue;
}