<script>
    export let id;
    export let color;
    export let addClass;
</script>

<div
    id={id}
    style="color: {color};"
    class="
    relative
    font-chronicaBold
    text-sm md:text-lg lg:text-3xl
    leading-relaxed
    mx-auto
    max-w-sm md:max-w-5xl
    {addClass ? addClass : ''}"
>
    <slot></slot>
    <slot name="image"></slot>
</div>
<style>

</style>