import { gsap } from 'gsap';
import handData from './../../data/discoverSliderData/ModernSlaveryStatements.json';
let handObj;
let finalHandData;
let prevHandValue;
function addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function animateHands(a) {
    handObj = handData.find(item => item.year === a);
    let currHandValue = handObj.ModernSlaveryStatementCompanies;
    finalHandData = gsap.to('#handNumber', {
        textContent: handObj.ModernSlaveryStatementCompanies,
        duration: 0.5,
        ease: 'power1.inOut',
        snap: { textContent: 1 },
        onUpdate: function() {
            this.targets()[0].innerHTML = 
            addCommas(Math.ceil(this.targets()[0].textContent));
        }
    })
    if (a === '2015') {
        gsap.to('#handSil', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#raisedHand1', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#raisedHand2', {
            yPercent: 100,
            duration: 1,
        })
        gsap.to('#raisedHand3', {
            yPercent: 100,
            duration: 1,
        })
        gsap.to('#raisedHand4', {
            yPercent: 100,
            duration: 1,
        })
    }
    if (a === '2017') {
        gsap.to('#handSil', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#raisedHand1', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#raisedHand2', {
            yPercent: 0,
            duration: 1,
        })
        gsap.to('#raisedHand3', {
            yPercent: 100,
            duration: 1,
        })
        gsap.to('#raisedHand4', {
            yPercent: 100,
            duration: 1,
        })
    }
    if (a === '2019') {
        gsap.to('#handSil', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#raisedHand1', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#raisedHand2', {
            yPercent: 0,
            duration: 1,
        })
        gsap.to('#raisedHand3', {
            yPercent: 0,
            duration: 1,
        })
        gsap.to('#raisedHand4', {
            yPercent: 100,
            duration: 1,
        })
    }
    if (a === '2021') {
        gsap.to('#handSil', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#raisedHand1', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#raisedHand2', {
            yPercent: 0,
            duration: 1,
        })
        gsap.to('#raisedHand3', {
            yPercent: 0,
            duration: 1,
        })
        gsap.to('#raisedHand4', {
            yPercent: 0,
            duration: 1,
        })
    }
    if (a === '2011' || a === '2012' || a === '2013' || a === '2014') {
        gsap.to('#handSil', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#raisedHand1', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#raisedHand2', {
            yPercent: 100,
            duration: 1,
        })
        gsap.to('#raisedHand3', {
            yPercent: 100,
            duration: 1,
        })
        gsap.to('#raisedHand4', {
            yPercent: 100,
            duration: 1,
        })
    }
    // prevHandValue = currHandValue;
}