<script>
    export let full = false;
    export let order;
    export let title;
    export let text;
    export let number;

    export let containerClass;
    export let imageContainerClass;
    export let imageContainerStyle;
    export let textClass;
    export let textStyle;
    export let align;
</script>

<div class="{full ? 'w-full' : 'w-2/5'} h-1/3 lg:w-1/5 lg:h-40 {order} {containerClass ? containerClass : ''}">
    <div class="{imageContainerClass ? imageContainerClass : ''} relative" style="{imageContainerStyle ? imageContainerStyle : ''}">
        <slot></slot>
    </div>
    <div style="{textStyle ? textStyle : ''}" class="{textClass ? textClass : ''} {align} lg:text-center pt-2">
        <p id='{title}Number' class='font-baskervilleReg text-3xl leading-none' style='color: #8AA9BA'>{number}</p>
        <p class='font-baskervilleIta text-sm leading-none' style='color: #0F1829'>{@html text}</p>
        <slot name="special"></slot>
    </div>
</div>