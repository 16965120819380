// Animation scripting
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
gsap.registerPlugin(ScrollTrigger);

function addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function mediaQuery() {
    ScrollTrigger.matchMedia({
        // small
        "(min-width: 0px) and (max-width: 767px)": function() {
            gsap.set('#shirt-container', {
                scale: '0.5',
            })
            gsap.set('#bike-container', {
                scale: '0.5',
            })
            gsap.set('#chopOne', {
                scale: '0.75',
            })
            gsap.set('#chopTwo', {
                scale: '0.75',
            })
            gsap.set('#shrimp-container', {
                scale: '0.5',
            })
            pinChain('-=70')
        },
        // medium
        "(min-width: 768px) and (max-width: 1023px)": function() {
            gsap.set('#shirt-container', {
                scale: '0.75',
            })
            gsap.set('#bike-container', {
                scale: '0.75',
            })
            gsap.set('#chopOne', {
                scale: '1',
            })
            gsap.set('#chopTwo', {
                scale: '1',
            })
            gsap.set('#shrimp-container', {
                scale: '0.75',
            })
            pinChain('-=70')
        },
        // large
        "(min-width: 1024px)": function() {
            gsap.set('.hand', {
                scale: '1.5',
            })
            gsap.set('#shirt-container', {
                scale: '1',
                right: '-10%',
            })
            gsap.set('#bike-container', {
                scale: '1',
            })
            gsap.set('#chopOne', {
                scale: '1.25',
            })
            gsap.set('#chopTwo', {
                scale: '1.25',
            })
            gsap.set('#shrimp-container', {
                scale: '1',
            })
            pinChain('-=70');
        },
    });
}

function pinChain(endValue) {
    ScrollTrigger.create({
        trigger: '#chain-section',
        pin: true,
        start: 'top top',
        end: `bottom bottom${endValue}`,
        // markers: true,
        scrub: true,
        pinSpacing: true,
    })
};

export function welcomeTimeline() {
// preset red hand 2 off screen
gsap.set('#hand-2', {
    right: 'calc(100vw + 130%)',
})
// preset yellow hand 6 off screen
gsap.set('#hand-6', {
    right: 'calc(100vw + 130%)',
})
// pinChain('-=70');
const tl = gsap.timeline({
    scrollTrigger: {
        trigger: '#mainAnimation',
        start: 'top top',
        // end: '+=1900',
        end: 'bottom bottom-=100',
        // pin: '#chain-section',
        // pinSpacing: true,
        scrub: true,
        // markers: true,
    },
    // to help animation happen in firefox, add rotation
    rotation:0.01,
    ease: 'power1',
})
tl
    // Chain Intro
    .from('#text-1', {
        opacity: '0',
        scale: '100',
    }, 'chain')
    .to('#chainFull', {
        visibility: 'hidden',
    },)
    .from('#chainTop', {
        visibility: 'hidden',
    }, 'chain')
    .from('#chainBottom', {
        visibility: 'hidden',
    }, 'chain')
    .to('#chainTop', {
        x: '25vw',
        y: '-15vw',
    }, 'chainMove-=0.5')
    .to('#chainBottom', {
        x: '-25vw',
        y: '15vw',
    }, 'chainMove-=0.5')
    // Hand 1
    .from('#text-2', {
        xPercent: '150',
    }, '>+=2')
    .to('#hand-1', {
        left: '150%',
    })
    // Hand 2
    .to('#hand-2', {
        right: '85%',
        // yoyo: true,
        // repeat: 1,
        // ease: 'none'
    }, '>+=1')
    .from('#text-3', {
        xPercent: '130',
        ease: 'slow (0.5, 0.5, false'
    })
    .to('#hand-2', {
        right: '140%',
        // yoyo: true,
        // repeat: 1,
        // ease: 'none'
    })
    // Shirt
    .from('#text-4', {
        x: '-100vw'
    }, '>+=1')
    .from('#shirt-container', {
        x: '160vw',
        y: '-75vw',
        opacity: '0',
    }, '>')
    .to('#hand-3',{
        x: '150vw',
        y: '-80vw',
    }, '>')
    .from('#shirt-text-1', {
        textContent: 0,
        duration: 1.2,
        ease: 'power1.in',
        snap: { textContent: 1 },
        onUpdate: function() {
            this.targets()[0].innerHTML = 
            addCommas(Math.ceil(this.targets()[0].textContent));
        },
    }, '<-=0.25')
    // Bike
    .from('#bike-container', {
        x: '100vw',
    }, '>+=2')
    .to('#hand-4', {
        xPercent: '120',
    }, '>')
    .from('#bike-text-1', {
        textContent: 0,
        duration: 2,
        ease: 'power1.in',
        snap: { textContent: 1 },
        onUpdate: function() {
            this.targets()[0].innerHTML = 
            addCommas(Math.ceil(this.targets()[0].textContent));
        },
    }, '<-=0.25')
    // Shrimp
    .fromTo('#chopOne', {
        x: '0',
        y: '0',
        rotation: '0',
    }, {
        x: '-140vw',
        y: '-15vw',
        rotation: '60deg',
    }, '>+=3')
    .fromTo('#chopTwo', {
        x: '0',
        y: '0',
        rotation: '0',
    }, {
        x: '-140vw',
        y: '-15vw',
        rotation: '40deg',
    }, '<+=0.1')
    .fromTo('#shrimp-container', {
        xPercent: '-80',
    }, {
        // xPercent: '0',
        x: '65vw'
    })
    .from('#shrimp-text-1', {
        textContent: 0,
        duration: 1.2,
        ease: 'power1.in',
        snap: { textContent: 1 },
        onUpdate: function() {
            this.targets()[0].innerHTML = 
            addCommas(Math.ceil(this.targets()[0].textContent));
        },
    }, '<+=0.5')
    .fromTo('#chopOne', {
        x: '-140vw',
        y: '-15vw',
        rotation: '60deg',
    }, {
        x: '-55vw',
        y: '-25vw',
        rotation: '70deg',
    }, '<-=0.5')
    .fromTo('#chopTwo', {
        x: '-140vw',
        y: '-15vw',
        rotation: '40deg',
    }, {
        x: '-55vw',
        y: '-20vw',
        rotation: '45deg',
    }, '<')
    .fromTo('#chopOne', {
        x: '-55vw',
        y: '-25vw',
        rotation: '70deg',
    }, {
        x: '-20vw',
        y: '-0vw',
        rotation: '45deg',
    })
    .fromTo('#chopTwo', {
        x: '-55vw',
        y: '-20vw',
        rotation: '45deg',
    }, {
        x: '-20vw',
        y: '0vw',
        rotation: '20deg',
    }, '<')
    // Bottom Text
    // They cared
    .set('#hand-5', {
        rotation: '-7deg',
    }, '>+=3')
    .from('#text-5', {
        x: '100vw',
        y: '100vw',
    })
    .from('#hand-5', {
        x: '100vw',
        y: '100vw',
        yoyo: true,
        repeat: 1,
    }, '<')
    // They asked...
    .from('#text-6', {
        x: '-100vw',
    }, '>+=1')
    // and now...
    .from('#text-7', {
        x: '-100vw',
    })
    // ten years ago...
    .from('#text-8', {
        xPercent: '140',
        ease: 'power3.out',
    })
    .to('#hand-6', {
        right: '97%',
        yoyo: true,
        repeat: 1,
        ease: 'power3.out',
    }, '<')
    // Now its those...
    .set('#hand-7', {
        rotation: '5deg'
    })
    .from('#text-9', {
        x: '-100vw',
        y: '100vw',
    })
    .from('#hand-7', {
        x: '-150vw',
        y: '100vw',
        yoyo: true,
        repeat: 1,
    }, '<');
return tl;
}


export function mapTimeline() {
    const tl = gsap.timeline({
        // scrollTrigger: {
        //     trigger: '#discoverAnimation',
        //     start: 'top bottom',
        //     end: 'top top',
        //     markers: true,
        // },
        // // to help animation happen in firefox, add rotation
        // rotation:0.01,
        // ease: 'power1',
    })
    tl
        .set('#locations > circle', {
            opacity: 0,
        })
        .from('#discoverAnimation', {
            backgroundColor: 'transparent',
            // opacity: '0',
        })
        // North America
        .from('#north-america', {
            x: '-200vw',
        }, '<')
        // South America
        .from('#south-america', {
            x: '-150vw',
        }, '<+=0.1')
        // Russia
        .from('#europe', {
            x: '250vw',
        }, '<+=0.05')
        // Africa
        .from('#africa', {
            x: '300vw',
        }, '<+=0.15')
        // Australia
        .from('#australia', {
            x: '150vw',
        }, '<+=0.17')
        // Japan
        .from('#island-right-seven', {
            x: '150vw',
        }, '<+=0.07')
        return tl;
}