<script>
export let url;
export let buttonAction = (url) => window.open(url, '_blank').focus();
export let dark;
export let addClass;
export let solid;
</script>

<button
    dark={false}
    style="border-color: {dark ? '#DAE451' : '#0F1829'}"
    class="
    h-9
    flex
    flex-row
    justify-center
    items-center
    font-chronicaBold 
    text-base 
    px-3
    py-3
    hover:opacity-75
    {addClass ? addClass : ''}
    {solid === true ? 'bg-black' : 'bg-transparent '}
    {solid === false ? ( dark === true ? 'border-3' : 'border-8') : 'border-0'} 
    {dark === true ? 'text-white' : 'text-black'} 
    {dark === true ? 'uppercase' : ''}
    "
    on:click={buttonAction(url)}
>
<slot>Button Text</slot>
</button>
<style>
button:hover {
    box-shadow: inset 2px 2px 12px -2px rgba(255, 255, 255, 0.75);
}
</style>