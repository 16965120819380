<script>
    import { getContext } from 'svelte';
    import ShareDialog from './share-dialog.svelte';
    import { gsap } from 'gsap';
  
    const { open } = getContext('simple-modal');
  
    const showDialog = () => {
      open(ShareDialog, { 
        resultTotal: resultTotal,
        resultProduct: resultProduct,
        resultCountry: resultCountry,
        resultYear: resultYear,
      }, {
        styleBg: {
          top: 'unset',
          bottom: '0',
        },
        styleWindowWrap: {
          margin: '0 36px',
        },
        styleWindow: { 
          background: '#FCF5EB',
        },
        styleContent: {
          padding: '90px 60px',
        }
      });
    };
    export let resultTotal;
    export let resultProduct;
    export let resultCountry;
    export let resultYear;
</script>

<div id="summary" class='absolute bottom-12 right-0 z-30 w-36 align-left text-white p-3 md:w-80 md:p-12' style="background-color: rgba(15, 24, 41, 0.7);">
  <p id="summaryTotal" class="font-baskervilleReg text-2xl md:text-5xl md:pb-6">{resultTotal ? resultTotal : 0}</p>
  <p class="font-chronicaLightIta text-base capitalize md:text-2xl md:pb-3">{resultProduct ? resultProduct : 'Products'}</p>
  <p class="font-chronicaBook text-xs uppercase md:text-xl md:pb-3">were shared by consumers {resultCountry ? `in ${resultCountry}` : 'globally'} in {resultYear}</p>
  <button class="font-chronicaBold text-xs uppercase underline border-none md:text-lg" style="color: #DAE151;" on:click={showDialog}>Share Fact</button>
</div>

