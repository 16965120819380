import { gsap } from 'gsap';
import shipData from './../../data/discoverSliderData/ShipmentsSeized.json';
let shipObj;
let finalShipData;
let prevShipValue;
export function animateShips(a) {
    shipObj = shipData.find(item => item.year === a);
    let currShipValue = shipObj.suppliersSeized;
    finalShipData = gsap.to('#shipNumber', {
        textContent: shipObj.suppliersSeized,
        duration: 0.5,
        ease: 'power1.inOut',
        snap: { textContent: 1 },
    })
    if (currShipValue !== '0') {
        gsap.to('#shipSil', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#ship1-container', {
            opacity: 1,
            duration: 1,
        })
        gsap.fromTo('#handDock', {
            xPercent: 100,
        }, {
            xPercent: 0,
            duration: 1,
        })
        gsap.to('#handDockSmall', {
            'clip-path': 'polygon(0px 0, 30% 0px, 30% 100%, 0px 100%)'
        })
    }
    if (currShipValue === '0') {
        gsap.to('#shipSil', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#ship1-container', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#handDock', {
            xPercent: 100,
            duration: 1,
        })
        gsap.to('#handDockSmall', {
            'clip-path': 'polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%)'
        })
    }
    // prevShipValue = currShipValue;
}