<script>
import GraphicStack from './graphic-stack-2.svelte';
import Silhouette from './svg-sil.svelte';

// pass props to Graphic Stack
export let number;
export let order;

// props for this component
let title = 'book';
</script>

<GraphicStack
    order={order}
    title={title}
    text={'Laws Passed'}
    number={number}
    align={'text-left'}
    imageContainerClass={'h-20 lg:h-28 lg:flex lg:items-center lg:justify-center lg:transform lg:scale-150'}
>
    <div class="absolute top-0 left-0 lg:relative" style='height: 70px; width: 80px;'>
        <object id='pad' style='height: 48px; width: auto; z-index: 11;' class='absolute bottom-0 right-0' title='gavel' data="assets/images/discover/gavel-pad.svg" type="image/svg+xml"></object>
        <object id='hammer' style='height: 48px; width: auto; z-index: 11;' class='absolute bottom-0 right-0' title='gavel' data="assets/images/discover/gavel-hammer.svg" type="image/svg+xml"></object>
        <object id='book' style='height: 70px; width: auto; opacity:0;' class='absolute top-0 left-0' title='book' data="assets/images/discover/book.svg" type="image/svg+xml"></object>
        <Silhouette
            svg={'book'}
            title={title}
            addSVGClass={'top-0 left-0'}
            addSVGStyle={'height: 70px; width: auto;'}
        ></Silhouette>
    </div>
</GraphicStack>