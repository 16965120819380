<script>
    
</script>

<div class="chop-container -top-56 md:-top-12 lg:top-32">
    <object id="chopOne" class="chop-one absolute" title="chopstick" data="assets/images/welcome/stick_1.svg" type="image/svg+xml"></object>
    <object id="chopTwo" class="chop-two absolute" title="chopstick" data="assets/images/welcome/stick_2.svg" type="image/svg+xml"></object>
</div>

<style>
.chop-one, .chop-two {
    width: 20px;
    height: 500px;
    transform-origin: bottom left;
}
.chop-one {
    right: 18px;
    top: -20px;
    z-index: 10;
}
.chop-two {
    right: 0;
    top: 0;
}
.chop-container {
    position: absolute;
    width:100%;
}




/* small */
@media screen and (min-width: 0px) {

}
/* medium */
@media screen and (min-width: 768px) {

}
/* large */
@media screen and (min-width: 1024px) {

}
</style>