<script>
import GraphicStack from './graphic-stack-2.svelte';
import Silhouette from './svg-sil.svelte';

// pass props to Graphic Stack
export let number;
export let order;

// props for this component
let title = 'hand';
</script>

<GraphicStack
    order={order}
    title={title}
    text={'Modern Slavery Statements'}
    number={number}
    containerClass={'flex flex-row items-end justify-end lg:block'}
    align={'text-right md:text-center'}
    textClass={'order-1 pb-6 white-space-normal lg:white-space-nowrap w-20 lg:w-full'}
    imageContainerClass={'order-2 h-20 w-1/2 lg:w-full lg:h-28'}
    imageContainerStyle={'clip-path: polygon(0 -70%, 130% -70%, 130% 100%, 0% 100%);'}
>
    <div class="absolute bottom-0 right-0 w-full h-full">
        <object id='raisedHand1' class='raised-hand lg:transform lg:translate-x-1/2 z-10' title='raisedHand' data="assets/images/discover/hand_4.svg" type="image/svg+xml"></object>
        <object id='raisedHand2' class='raised-hand mr-7 lg:mr-4' title='raisedHand' data="assets/images/discover/hand_2.svg" type="image/svg+xml"></object>
        <object id='raisedHand3' class='raised-hand mr-12' title='raisedHand' data="assets/images/discover/hand_3.svg" type="image/svg+xml"></object>
        <object id='raisedHand4' class='raised-hand -mr-7 lg:-mr-14' title='raisedHand' data="assets/images/discover/hand_1.svg" type="image/svg+xml"></object>
        <Silhouette
            svg={'hand'}
            title={title}
            addSVGClass={'bottom-0 right-0 lg:right-1/2 lg:transform lg:translate-x-1/2'}
            addSVGStyle={'height: auto; width: 40px;'}
        ></Silhouette>
    </div>
</GraphicStack>

<style>
    .raised-hand {
        height: auto;
        width: 40px;
        @apply absolute bottom-0 right-0 lg:right-1/2;
    }
</style>