import { gsap } from 'gsap';
import radarData from './../../data/discoverSliderData/SuppliersMonitored.json';
let radarObj;
let finalRadarData;
let prevRadarValue;
function addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function animateRadar(a) {
    radarObj = radarData.find(item => item.year === a);
    let currRadarValue = radarObj.suppliersMonitored;
    finalRadarData = gsap.to('#radarNumber', {
        textContent: radarObj.suppliersMonitored,
        duration: 0.5,
        ease: 'power1.inOut',
        snap: { textContent: 1 },
        onUpdate: function() {
            this.targets()[0].innerHTML = 
            addCommas(Math.ceil(this.targets()[0].textContent));
        }
    })
    if (a === '2020') {
        gsap.to('#radarSil', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#radar1', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#radar2', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#radar3', {
            opacity: 0,
            duration: 1,
        })
    }
    if (a === '2021') {
        gsap.to('#radarSil', {
            opacity: 0,
            duration: 1,
        })
        gsap.to('#radar1', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#radar2', {
            opacity: 1,
            duration: 1,
        })
        gsap.to('#radar3', {
            opacity: 0,
            duration: 1,
        })
    }
    if (currRadarValue === '0') {
        gsap.to('#radarSil', {
            opacity: 1,
            duration: 1,
        })
    }
    // prevRadarValue = currRadarValue;
}
