<script>
import GraphicStack from './graphic-stack-2.svelte';
import Silhouette from './svg-sil.svelte';

// pass props to Graphic Stack
export let number;
export let order;

// props for this component
let title = 'ship';
</script>

<GraphicStack
    full={true}
    order={order}
    title={title}
    text={'Shipments Seized'}
    number={number}
    align={'text-left md:text-center'}
    textClass={'relative mt-12 ml-5 pl-14 lg:mt-0 lg:ml-0 lg:pl-0 w-1/2 lg:w-full'}
    containerClass={'flex flex-row lg:flex-col lg:justify-center lg:items-center'}
    imageContainerClass={'mt-12 lg:mt-0 w-1/2'}
>
<div class=' h-20 lg:h-28 w-full lg:relative lg:transform lg:scale-150'>
    <div id="ship1-container" class='lg:transform lg:-scale-x-1 absolute top-0 right-0 overflow-hidden lg:top-1/4 lg:left-1/2 lg:-translate-x-1/4 lg:-ml-3 lg:right-auto' style='height: 60px; width: 130px; opacity:0;'>
        <object id='ship1' style='height: 60px; width: 86px;' class=' ship absolute top-0 right-0' title='ship' data="assets/images/discover/ship_2.svg" type="image/svg+xml"></object>
        <object id='water1' style='height: 24px; width: auto;' class='water absolute -bottom-4 z-10' title='water' data="assets/images/discover/water.svg" type="image/svg+xml"></object>
    </div>
    <div id="ship2-container" class='lg:left-14 lg:-top-3 lg:transform lg:-scale-x-1 absolute -top-9 left-0 overflow-hidden' style='height: 60px; width: 130px; opacity:0;'>
        <object id='ship2' style='height: 60px; width: 86px;' class='ship absolute top-0 left-0' title='ship' data="assets/images/discover/ship_1.svg" type="image/svg+xml"></object>
        <object id='water2' style='height: 24px; width: auto;' class='water absolute -bottom-4 z-10' title='water' data="assets/images/discover/water.svg" type="image/svg+xml"></object>
    </div>
    <div id="ship3-container" class='lg:left-14 lg:top-16 lg:transform lg:-scale-x-1 absolute top-9 left-0 overflow-hidden' style='height: 60px; width: 130px; opacity:0;'>
        <object id='ship3' style='height: 60px; width: 86px;' class='ship absolute top-0 left-0' title='ship' data="assets/images/discover/ship_3.svg" type="image/svg+xml"></object>
        <object id='water3' style='height: 24px; width: auto;' class='water absolute -bottom-4 z-10' title='ship' data="assets/images/discover/water.svg" type="image/svg+xml"></object>
    </div>
    <Silhouette
        svg={'ship'}
        title={title}
        addSVGClass={'lg:transform lg:-scale-x-1 top-0 right-0 lg:top-1/4 lg:left-1/2 lg:-translate-x-1/2 lg:right-auto'}
        addSVGStyle={'height: 60px; width: 86px;'}
    ></Silhouette>
    <object id='handDockSmall' style='height: 60px; width: auto; clip-path: polygon(0px 0, 30% 0px, 30% 100%, 0px 100%);' class='transform -scale-x-1 hidden lg:block absolute top-6 right-full lg:mr-none md:mr-3' title='hand-dock' data="assets/images/discover/dock.svg" type="image/svg+xml"></object>
</div>
<object slot="special" id='handDock' style='height: 90px; width: 100%;' class='lg:hidden absolute -top-2 left-0' title='hand-dock' data="assets/images/discover/dock.svg" type="image/svg+xml"></object>
</GraphicStack>

<style>
    
</style>