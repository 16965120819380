<script>
    
</script>

<div class="h-screen w-screen absolute">
<object id="chainTop" class="chain absolute" title="chain" data="assets/images/welcome/chain/chain_1.svg" type="image/svg+xml"></object>
</div>

<style>
/* Style in chain-full */
</style>