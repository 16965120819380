<script>
import GraphicStack from './graphic-stack-2.svelte';
import Silhouette from './svg-sil.svelte';

// pass props to Graphic Stack
export let number;
export let order;

// props for this component
let title = 'avatar';
</script>

<GraphicStack
    order={order}
    title={title}
    text={'FRDM Fighters'}
    number={number}
    align={'text-right md:text-center'}
    imageContainerClass={'h-20 lg:h-28'}
>
    <div class="absolute top-0 left-0 lg:right-0 lg:left-auto h-full w-full">
        <object id='avatarTwo' style='z-index:1;' class='avatar right-8 lg:right-2' title='avatar' data="assets/images/discover/avatar_4.svg" type="image/svg+xml"></object>
        <object id='avatarThree' style='z-index:4;' class='avatar right-16' title='avatar' data="assets/images/discover/avatar_1.svg" type="image/svg+xml"></object>
        <object id='avatarFour' style='z-index:3;' class='avatar right-24 lg:right-44' title='avatar' data="assets/images/discover/avatar_3.svg" type="image/svg+xml"></object>
        <object id='avatarFive' style='z-index:2;' class='avatar right-36 lg:right-60' title='avatar' data="assets/images/discover/avatar_5.svg" type="image/svg+xml"></object>
        <object id='avatarOne' style='z-index:5;' class='avatar right-0 lg:right-1/2' title='avatar' data="assets/images/discover/avatar_2.svg" type="image/svg+xml"></object>
        <Silhouette
            svg={'man'}
            title={title}
            addSVGClass={'top-0 right-0 lg:right-1/2 lg:transform lg:translate-x-1/2 lg:scale-150 origin-top-right'}
            addSVGStyle={'height: 70px; width: auto;'}
        ></Silhouette>
    </div>
</GraphicStack>
<style>
.avatar {
    height: 70px;
    width: auto;
    @apply absolute top-0 lg:transform lg:scale-150 lg:translate-x-1/2 origin-top-right;
}
</style>