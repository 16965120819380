<script>
    export let number;
</script>

<div
id="shirt-container"
class="absolute shirt-container">
    <span id="shirt-hanger" class="inline-block w-7 h-7 rounded-full absolute z-10" style="left: 43%; top:22px; background-color: #F6DEA7; box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);"></span>
    <p id="shirt-text-1" class="font-baskervilleReg text-7xl text-white">{number}</p>
    <p id="shirt-text-2" class="font-chronicaBold text-7xl text-white">shirts</p>
    <object class="absolute shirt" title="bike" data="assets/images/welcome/shirt.svg" type="image/svg+xml"></object>
    <slot name="image"></slot>
</div>

<style>
.shirt {
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
}
.shirt-container {
    width: 600px;
    height: 608px;
    top:0;
    right:-30%;
    /* left: 0; */
    transform-origin: top right;
    /* for text */
    padding-top: 200px;
}





/* small */
@media screen and (min-width: 0px) {

}
/* medium */
@media screen and (min-width: 768px) {

}
/* large */
@media screen and (min-width: 1024px) {

}
</style>