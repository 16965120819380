<script>
    export let id;
    export let color;
    export let addClass;
</script>

<p 
    id={id}
    style="color: {color};"
    class="
    relative
    font-baskervilleBold
    text-3xl md:text-4xl lg:text-6xl
    mx-auto
    max-w-sm md:max-w-5xl
    {addClass ? addClass : ''}"
>
    <slot></slot>
    <slot name="image"></slot>
</p>