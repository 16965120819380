<script>
export let number;
</script>

<div
id="shrimp-container"
class="absolute shrimp-container bg-contain bg-no-repeat">
    <div style="width: 500px; margin-left: -500px;" class="text-right"> 
        <p id="shrimp-text-1" class="font-baskervilleReg text-7xl">{number}</p>
        <p class="font-chronicaBold text-5xl">preferences for shrimp</p>
    </div>
    <object class="shrimp absolute top-0 left-0" title="bike" data="assets/images/welcome/shrimp.svg" type="image/svg+xml"></object>
</div>

<style>
.shrimp {
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
}
.shrimp-container {
    width: 500px;
    height: 500px;
    top:0;
    left: 0;
    transform-origin: top right;
    /* for text */
    padding-top: 100px;
    color: #FA9975;
}





/* small */
@media screen and (min-width: 0px) and (max-width: 767px) {
    .shrimp-container > div {
        margin-left: -68% !important;
        margin-top: 63%;
    }
}
/* medium */
@media screen and (min-width: 768px) {

}
/* large */
@media screen and (min-width: 1024px) {

}
</style>