import { gsap } from 'gsap';
import fighterData from './../../data/discoverSliderData/FreedomFighters.json';
let fighterObj;
let finalFighterData;
function addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function animateFighters(a) {
    fighterObj = fighterData.find(item => item.year === a);
    finalFighterData = gsap.to('#avatarNumber', {
        textContent: fighterObj.freedomFighterCount,
        duration: 0.5,
        ease: 'power1.inOut',
        snap: { textContent: 1 },
        onUpdate: function() {
            this.targets()[0].innerHTML = 
            addCommas(Math.ceil(this.targets()[0].textContent));
        }
    })
    switch (a) {
        case '2011':
            gsap.to('#avatarSil', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarOne', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarTwo', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarThree', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarFour', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarFive', {
                opacity: 0,
                duration: 1,
            })
            break;
        case '2013':
            gsap.to('#avatarSil', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarOne', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarTwo', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarThree', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarFour', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarFive', {
                opacity: 0,
                duration: 1,
            })
            break;
        case '2015':
            gsap.to('#avatarSil', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarOne', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarTwo', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarThree', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarFour', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarFive', {
                opacity: 0,
                duration: 1,
            })
            break;
        case '2017':
            gsap.to('#avatarSil', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarOne', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarTwo', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarThree', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarFour', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarFive', {
                opacity: 0,
                duration: 1,
            })
            break;
        case '2019':
            gsap.to('#avatarSil', {
                opacity: 0,
                duration: 1,
            })
            gsap.to('#avatarOne', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarTwo', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarThree', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarFour', {
                opacity: 1,
                duration: 1,
            })
            gsap.to('#avatarFive', {
                opacity: 1,
                duration: 1,
            })
            break;
        
    }
}