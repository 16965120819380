<script>
    export let resultCountry;
    export let resultTotal;
    export let resultProduct;
    export let resultYear;

    import IconButton from './../basic/icon-button.svelte';
</script>

<div class="text-center">
    <p class="font-chronicaBook text-lg leading-7">Freedom Fighters like you <span class="highlight">{resultCountry ? `in ${resultCountry}` : 'Globally'}</span> demanded their <span class="highlight">{resultTotal} {resultProduct ? resultProduct : 'Products'}</span> be made ethically in <span class="highlight">{resultYear}</span>, showing they care about slavery in supply chains.</p>
    <!-- <Button addClass={'w-full py-3 my-6'}>Share This Fact</Button> -->
    <p class="font-chronicaBold text-sm leading-7 uppercase mt-4">Share This Fact</p>
    <p class="font-chronicaBold text-sm leading-7 uppercase">with your favorite brands<br>by tagging them on social media!</p>
   
    <div class="social-media mx-auto flex flex-row justify-center items-center gap-2 mt-9">
        <IconButton
            dark={true}
            size={'large'}
            iconName={'facebook'}
            url={'https://www.facebook.com/sharer.php?u=https%3A%2F%2Fslaveryfootprint.org'}
        />
        <IconButton
            dark={true}
            size={'large'}
            iconName={'twitter'}
            url={'https://twitter.com/intent/tweet?url=https%3A%2F%2Fslaveryfootprint.org&text=How%20many%20slaves%20work%20for%20you%3F&hashtags=FRDM,slaveryfootprint'}
        />
        <IconButton
            dark={true}
            size={'large'}
            iconName={'linkedin'}
            url={'https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fslaveryfootprint.org&title=How%20many%20slaves%20work%20for%20you%3F&summary=There%20are%2027%20million%20slaves%20in%20the%20world%20today.%20Many%20of%20them%20contribute%20to%20the%20supply%20chains%20that%20end%20up%20in%20the%20products%20we%20use%20every%20day.%20Find%20out%20how%20many%20slaves%20work%20for%20you%2C%20and%20take%20action.'}
        />
    </div>
</div>

<style>
    .highlight {
        font-family: 'Chronica Pro Bold';
        text-transform: uppercase;
    }
</style>