<script>
import GraphicStack from './graphic-stack-2.svelte';
import Silhouette from './svg-sil.svelte';

// pass props to Graphic Stack
export let number;
export let order;

// props for this component
let title = 'radar';
</script>

<GraphicStack
    order={order}
    title={title}
    text={'Suppliers Monitored'}
    number={number}
    containerClass={'flex flex-col'}
    align={'text-left md:text-center'}
    textClass={'pb-6'}
    imageContainerClass={'h-20 w-full lg:h-28 lg:flex lg:justify-center lg:items-end'}
>
<div class='absolute top-0 left-0 h-20 lg:h-28 w-16 lg:relative lg:transform lg:scale-150'>
    <object id='radarHighlight' class='radar radar-highlight z-10' title='radar' data="assets/images/discover/radar_highlight.svg" type="image/svg+xml"></object>
    <object id='radar1' class='radar' title='radar' data="assets/images/discover/radar_1.svg" type="image/svg+xml"></object>
    <object id='radar2' class='radar' title='radar' data="assets/images/discover/radar_2.svg" type="image/svg+xml"></object>
    <object id='radar3' class='radar' title='radar' data="assets/images/discover/radar_3.svg" type="image/svg+xml"></object>
        <Silhouette
            svg={'radar'}
            title={title}
            addSVGClass={'top-0 left-0 lg:top-5'}
            addSVGStyle={'height: 67px; width: auto;'}
        ></Silhouette>
    </div>
</GraphicStack>

<style>
    .radar {
        height: 67px;
        width: auto;
        @apply absolute top-0 left-0 lg:top-5;
    }
    #radar2, #radar3 {
        opacity:0;
    }
</style>