<script>
    export let title;
    export let addSVGStyle;
    export let addSVGClass;
    export let svg;
</script>
<object 
    id="{title}Sil" 
    style="{addSVGStyle ? addSVGStyle : ''}" 
    class="absolute z-10 {addSVGClass ? addSVGClass : ''}" 
    title={title} data="assets/images/discover/silhouette/{svg}.svg" type="image/svg+xml">
</object>