<script>
// Svelte imports
import { onMount } from 'svelte';
import Map from './map.svelte';
import GraphicStack from './discover-contents/graphic-stack.svelte';
import LawGraphic from './discover-contents/lawGraphic.svelte';
import FighterGraphic from './discover-contents/fighterGraphic.svelte';
import StatementGraphic from './discover-contents/statementGraphic.svelte';
import RadarGraphic from './discover-contents/radarGraphic.svelte';
import ShipGraphic from './discover-contents/shipGraphic.svelte';
import Results from './discover-contents/dialog-trigger.svelte';
import Dialog from 'svelte-simple-modal';
import { animateFighters } from './../components/discover-contents/animateFighters.js';
let finalFighterData;
import { animateLaws } from './../components/discover-contents/animateLaws.js';
let finalLawData;
import { animateShips } from './../components/discover-contents/animateShips.js';
let finalShipData;
import { animateHands } from './../components/discover-contents/animateHands.js';
let finalHandData;
import { animateRadar } from './../components/discover-contents/animateRadar.js';
let finalRadarData;

// --------------------------------------

// Select scripts
import Select from 'svelte-select';
const selectProps = {
    isMulti: false,
    isSearchable: true,
    containerClasses: 'w-1/2',
    listOffset: 0,
}
import productData from './../data/Products.json';
const productOptionIdentifier = 'id';
const productLabelIdentifier = 'name';
let products = productData;
var productValue = null;
let productPlaceholder = "Pick a Product";
function handleProductSelect(event) {
    results();
//   console.log('selected product', event.detail);
  // .. do something here 🙂
}
function handleProductClear(event) {
    results();
}

import countriesData from './../data/countries.json';
const countryOptionIdentifier = 'iso2';
const countryLabelIdentifier = 'name';
let countries = countriesData;
let countriesValue = null;
let countryPlaceholder = 'Pick a Country';
function handleCountrySelect(event) {
    results();
//   console.log('selected country', event.detail);
  // .. do something here 🙂
}
function handleCountryClear(event) {
    results();
}

// --------------------------------------

// Slider
// Slider styles in global.css so they can be ignored by purgecss
import Slider from 'svelte-range-slider-pips';
import productPerYear from './../data/products-per-year.json';

const sliderSteps = Object.keys(productPerYear);
const sliderProps = {
    float: true,
    min: 0,
    max: Object.keys(productPerYear).length - 1,
    formatter: v => sliderSteps[v],
    springValues: { stiffness: 0.05, damping: 0.9 }
}
let yearValue = [0];

// --------------------------------------

// Modal
export let visible = true;
const handleModal = () => {
    if (visible) {
       visible = !visible;
    }
}

// --------------------------------------

// Animation scripting
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
import { mapTimeline } from './welcome-contents/timelines.js';

onMount(() => {
    function goToSection(section) {
        gsap.to(window, {
            scrollTo: {y: section, autoKill: false},
            duration: 1,
        });
    }
    var snapV =     [0, 1];
    const master = gsap.timeline({
        scrollTrigger: {
            trigger: '#discoverAnimation',
            start: 'top bottom-=200',
            end: 'bottom bottom',
            scrub: false,
            // snap: {
            //     snapTo: snapV,
            //     duration: {min: 0.1, max: 0.5},
            //     delay: 0,
            //     ease: "none"  
            // },
            // markers: true,
            // onEnter: () => goToSection('#discoverAnimation'),
            // onEnterBack: () => goToSection('#textTwoSection'),
        },
        // to help animation happen in firefox, add rotation
        rotation:0.01,
        ease: 'power1',
    });
    master
        .add(mapTimeline(), 'labels')
        .from('#modal', {
            opacity: '0',
        }, '<+=25%');
    // Preset summary block offscreen
    hideSummary();
    // constant moving waves
    gsap.to('.water', {
        x: "-100px",
        yoyo: true,
        repeat: -1,
        duration: 12,
        stagger: 0.5,
        ease: 'none',
    });
    //constantly bounce ship
    gsap.to('.ship', {
        y: '-2px',
        yoyo: true,
        repeat: -1,
        duration: 3,
        stagger: 0.7,
        ease: 'none',
    });
    // constantly rotate radar
    gsap.to('.radar-highlight', {
        rotation: '360',
        duration: 4,
        ease: 'none',
        repeat: -1,
    });
    // set initial slider values
    animateFighters('2011');
    animateLaws('2011');
    animateShips('2011');
    animateHands('2011');
    animateRadar('2011');
})

// --------------------------------------

// Manage data

let year = 2011;
let calcYearFinal;
let yearValueFinal;
let dataArrFinal;
let filterArr;
let resultsArr;
let resultsTotal;
let resultsCountry;
let resultsProduct;
let resultsYear;

// Master function that does stuff when the slider is moved or the dropdowns are engaged
function results() {
    calcYearFinal = yearValue[0] + year;
    yearValueFinal = String(calcYearFinal);
    dataArrFinal = productPerYear[yearValueFinal];

    resultsArr = dataArrFinal.filter(function(item, i) {
        if (productValue && countriesValue) {
            return item.id === productValue.id && item.iso2 === countriesValue.iso2;
        } else if (productValue) {
            return item.id === productValue.id;
        } else if (countriesValue) {
            return item.iso2 === countriesValue.iso2;
        } else {
            return dataArrFinal;
        }
    });

    resultsTotal = resultsArr
      .map(item => parseInt(item.total) || 0) // Coalesce null or NaN to 0
      .reduce((prev, next) => prev + next, 0)
      .toLocaleString("en-US");

    resultsCountry = countriesValue ? countriesValue.name : null;
    console.log('resultsCountry' + resultsCountry);
    resultsProduct = productValue ? productValue.name : null;
    console.log('resultsProduct' + resultsProduct);
    resultsYear = yearValueFinal;

    animateFighters(resultsYear);
    animateLaws(resultsYear);
    animateShips(resultsYear);
    animateHands(resultsYear);
    animateRadar(resultsYear);

    if (productValue || countriesValue || resultsTotal) {
        showSummary()
    } else {
        hideSummary()
    }

    animateLocation(
        countriesValue && productValue ? resultsArr
        : resultsProduct ? resultsArr
        : countriesValue ? countriesValue
        : null
        );

    return resultsArr, resultsTotal, resultsCountry, resultsProduct, resultsYear;
}
function animateLocation(a) {
    if (a) {
        gsap.to('#locations > circle', {
            opacity: 0,
        })
        for (let i = 0; i < a.length; i++) {
            // console.log(a[i]);
            // console.log(a[i].location);

            let cstr = null;
            if (a[i].iso2) {
                const country = countries.find(country => country.iso2 === a[i].iso2);
                cstr = country ? country.name : 'Unknown Country'; // Assign a default value if not found
            }
            else cstr = a[i].name; // full country name
            let str = cstr.replace(/\s+/g, '-').toLowerCase();
            // console.log(str);
            gsap.to(`#${str}`, {
                opacity: 1,
            })
        }
    } else {
        gsap.to('#locations > circle', {
            opacity: 0,
        })
    }
}

// --------------------------------------

// Summary functions
function showSummary() {
    gsap.to('#summary', {
        xPercent: '0',
    })
}
function hideSummary() {
    gsap.to('#summary', {
        xPercent: '300',
    })
}
</script>

<main id="discoverAnimation" on:mousedown="{handleModal}" class="relative w-screen h-screen overflow-hidden" style="background-color: #A4C8DC;">
    <div id='selectContainer' class="flex flex-row w-full absolute z-20" style="max-width: 550px; top: 54px;">
        <Select
            id={'selectOne'}
            {...selectProps}
            optionIdentifier={productOptionIdentifier}
            labelIdentifier={productLabelIdentifier}
            items={products}
            bind:value={productValue}
            placeholder={productPlaceholder}
            on:select={handleProductSelect}
            on:clear={handleProductClear}
        />
        <Select
            id={'selectTwo'}
            {...selectProps}
            optionIdentifier={countryOptionIdentifier}
            labelIdentifier={countryLabelIdentifier}
            items={countries}
            bind:value={countriesValue}
            placeholder={countryPlaceholder}
            on:select={handleCountrySelect}
            on:clear={handleCountryClear}
        />
    </div>
    <div id="modal" class="modal" class:visible>
        <div class="flex flex-row justify-between items-start mt-12 px-8" style="max-width: 500px;">
            <img src='./assets/images/discover/arrows/arrow-left.svg' alt="arrow">
            <p class='font-chronicaBook text-white text-center pt-3'>Use the dropdowns to pick <br> the products and countries you <br> want to know about</p>
            <img src='./assets/images/discover/arrows/arrow-right.svg' alt="arrow">
        </div>
        <div class="flex flex-row justify-start items-center mx-10 lg:mx-44 mt-60 lg:mt-96 lg:pt-48" style="max-width: 500px;">
            <img class="mr-3" src='./assets/images/discover/arrows/arrow-bottom.svg' alt="arrow">
            <p class='font-chronicaBook text-white text-left pt-3'>Use the slider to travel in time.</p>
        </div>
    </div>
    <section id="top" class="pt-12 h-2/5 md:h-3/4 relative">
        <Map
        />
        <Dialog>
            <Results
                resultTotal={resultsTotal}
                resultProduct={resultsProduct}
                resultCountry={resultsCountry}
                resultYear={resultsYear}
            />
        </Dialog>
    </section>

    <section id="bottom" class="h-3/5 md:h-1/4">
        <Slider
            bind:values={yearValue}
            on:change={(e) => {
                results();
                // animate();
            }}
            {...sliderProps}
        />
        <div class="
        w-full md:w-10/12 md:mx-auto
        flex flex-row flex-wrap md:flex-nowrap justify-center gap-x-9 pt-9
        " style="height: calc(100% - 8px);">
            <FighterGraphic
                order={'order-1'}
                bind:number={finalFighterData}
            />
            <LawGraphic
                order={'order-2'}
                bind:number={finalLawData}
            />
            <ShipGraphic
                order={'order-3 lg:order-6'}
                bind:number={finalShipData}
            />
            <StatementGraphic
                order={'order-4'}
                bind:number={finalHandData}
            />
            <RadarGraphic
                order={'order-5'}
                bind:number={finalRadarData}
            />
        </div>
    </section>
</main>

<style>
#discoverAnimation {
    padding: 0 10em;
}
.modal.visible {
    visibility: visible;
}
.modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 24, 41, 0.65);
    z-index: 19;
    opacity: 1;
    visibility: hidden;
    padding-top:60px;
}
</style>
