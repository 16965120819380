<script>
// Svelte imports
import { onMount } from 'svelte'

// Component imports
import TextBig      from './welcome-contents/text-big.svelte';
import TextSmall     from './welcome-contents/text-small.svelte'
import Shirt        from './welcome-contents/shirt.svelte';
import Bike         from './welcome-contents/bike.svelte';
import Shrimp       from './welcome-contents/shrimp.svelte';
import Chopsticks   from './welcome-contents/chopsticks.svelte';
import ChainTop     from './welcome-contents/chain-top.svelte';
import ChainBottom  from './welcome-contents/chain-bottom.svelte';
import ChainFull    from './welcome-contents/chain-full.svelte';
// import Button       from './basic/button.svelte';
import Hand         from './welcome-contents/hand.svelte';
import numbersjson from './../data/welcomeNumbers.json';

import { mediaQuery, welcomeTimeline  } from './welcome-contents/timelines';

// Animation scripting
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
gsap.registerPlugin(ScrollTrigger);

onMount(() => {
    mediaQuery();
    welcomeTimeline();
})

// // Manage State and Data
let totalFRDMFighters = 27; // in millions
// let totalShirts = 123456789;
// let totalBikes = 123456789;
// let totalShrimp = 123456789;

// shirt array
let shirtArray = [
    JSON.parse(numbersjson[1].total),
    JSON.parse(numbersjson[2].total),
    JSON.parse(numbersjson[3].total),
    JSON.parse(numbersjson[4].total),
    JSON.parse(numbersjson[8].total),
    JSON.parse(numbersjson[9].total),
    JSON.parse(numbersjson[10].total)
];
let totalShirts = shirtArray.reduce((pv, cv) => pv + cv, 0);
// I can either count up, or add commas, but I cant do both. I would have to use GSAP to add commas, but its not easy
// let totalShirts = shirtCombinedValues.toLocaleString();

// bike array
let bikeArray = [
    JSON.parse(numbersjson[0].total),
    JSON.parse(numbersjson[6].total),
    JSON.parse(numbersjson[7].total)
]
let totalBikes = bikeArray.reduce((pv, cv) => pv + cv, 0);

// Shrimp - no array, only one entry
let totalShrimp = JSON.parse(numbersjson[5].total);
</script>

<main id="mainAnimation" class="text-center" >
    <section id="chain-section" class="h-screen flex flex-col justify-center items-center relative" >
        <ChainFull />
        <ChainTop />
        <ChainBottom />
        <TextBig 
            id={'text-1'}
            color={'#0F172A'}
            addClass={'my-36'}
        >How many slaves<br>work for you?
        </TextBig>
    </section>
    <section id="textOneSection" class="relative mb-4 md:mb-8 lg:mb-12">
        <TextSmall
            id={'text-2'}
            color={'#4381AE'}
            addClass={'mb-5'}
        >
        10 years ago, that was the question heard 'round the world.
            <Hand
                slot='image'
                hand={1}
                addClass={'top-0 origin-top-left left-2/3 -top-8'}
                addStyle={''}
            />
        </TextSmall>
        <TextSmall
            id={'text-3'}
            color={'#D37F5D'}
            addClass={'mb-5'}
        >{totalFRDMFighters} million people from every nation on Earth responded.
            <Hand
                slot='image'
                hand={2}
                addClass={'w-auto h-96 origin-top-right -top-14 lg:-top-24'}
                addStyle={'right: 85%;'}
            />    
        </TextSmall>
    </section>
    <section id="shirtSection" class="relative section-common flex flex-row w-3/5 mx-auto">
        <TextBig
            id={'text-4'}
            color={'#9F897F'}
            addClass={'text-left ml-0 mr-0 max-w-none md:max-w-none'}
        >They told us<br>about...
        </TextBig>
        <Shirt number={totalShirts}>
            <Hand
                slot='image'
                hand={3}
                addClass={'bottom-full left-1/2 -ml-16 -mb-10 w-auto origin-bottom-left'}
                addStyle={'height: 569px;'}
            />
        </Shirt>
    </section>
    <section id="bikeSection" class="relative section-common bike-section">
        <Bike number={totalBikes}>
            <Hand
                slot='image'
                hand={4}
                addClass={'origin-top-left h-56 w-auto -top-5'}
            />
        </Bike>
    </section>
    <section id="shrimpSection" class="relative section-common">
        <Chopsticks />
        <Shrimp number={totalShrimp} />
    </section>
    <section id="textTwoSection" class="overflow-hidden">
        <TextBig
            id={"text-5"}
            color={"#7494A9"}
            addClass={'mt-16 mb-6'}
        >They cared.
            <Hand
                slot='image'
                hand={5}
                addClass={'origin-top-left -top-8 md:-top-12'}
                addStyle={'left: 60%'}
            />
        </TextBig>

        <TextSmall
            id={"text-6"}
            color={'#7494A9'}
            addClass={'mb-6 md:mb-16'}
        >They asked their favorite brands to care too.
        </TextSmall>

        <TextSmall
            id={"text-7"}
            color={'#7494A9'}
            addClass={'mb-6 md:mb-16'}
        >And now taking all that data, along with some important updates, and sharing it with the world.
        </TextSmall>

        <TextSmall
            id={"text-8"}
            color={'#B4B95A'}
            addClass={'mb-6 md:mb-16'}
        >Ten years ago, it was the world’s consumers finding out how many slaves work for them.
            <Hand
                slot='image'
                hand={6}
                addClass={'origin-top-right top-0 right-full'}
            />
        </TextSmall>
        <TextSmall
            id={"text-9"}
            color={'#828380'}
            addClass={'mb-16'}
        >Now it’s those same consumers asking the same question of their favorite brands.
            <Hand
                slt='image'
                hand={7}
                addClass={'-top-6 origin-top-right right-1/2'}
            />
        </TextSmall>

        <p
            id="text-10"
            style="color: #0F1829;"
            class="font-baskervilleBold text-2xl md:text-4xl lg:text-5xl"
        >SFP at 10</p>

        <p
            id="text-11"
            style="color: #E17F86;"
            class="font-baskervilleBold text-2xl md:text-5xl lg:text-6xl mb-16"
        >How many slaves<br>work for you?</p>

        <!-- <Button addClass={'mx-6 md:mx-auto'}>See What Powerful Tools You've Made</Button>

        <div class="flex flex-col lg:flex-row justify-center items-center mt-9">
            <p class="lg:mr-1 font-chronicaBook text-base" id="cta-text-2">Or if you haven’t entered yet, </p>
            <a id="cta-text-3" class="underline font-chronicaBook text-base" href="#">FIND YOUR SLAVERY FOOTPRINT</a>
        </div> -->
    </section>
</main>

<style>
:global(body) {
background-color: #FCF8F2;
width: 100vw;
overflow-X: hidden;
}
.section-common {
    height: 350px;
}
.section-common.bike-section {
    height: 300px;
}
@media screen and (min-width: 640px) {
    .section-common {
        height: 500px;
    }
    .section-common.bike-section {
        height: 325px;
    }
}
@media screen and (min-width: 1024px) {
    #chain-section {
        margin-bottom: 20vh;
    }
    .section-common {
        height: 600px;
    }
    .section-common.bike-section {
        height: 400px;
    }
}
</style>