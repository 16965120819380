<script>
export let url;
export let buttonAction = (url) => window.open(url, '_blank').focus();
export let dark;
export let addClass;
export let size;
export let iconName;
</script>

<button
    dark={false}
    style="background-color: {dark ? '#0F1829' : '#FCF5EB'}; aspect-ratio: 1;"
    class="
    flex
    justify-center
    items-center
    border-0
    rounded-full
    {addClass ? addClass : ''}
    {size === 'large' ? 'w-20 h-20' : 'w-8 h-8'}
    {dark === true ? 'text-white' : 'text-black'}
    "
    on:click={buttonAction(url)}
>
<svg 
    data-src="https://s2.svgbox.net/social.svg?ic={iconName}"
    width="{size === 'large' ? 40 : 16 }"
    height="{size === 'large' ? 40 : 16 }"
    color="{dark === true ? '#DAE151' : '#B8ABAA' }">
</svg>
</button>

<style>
button:hover {
    box-shadow: inset 2px 2px 12px -2px rgba(255, 255, 255, 0.75);
}
</style>