<script>
    
</script>
<div class="h-screen w-screen absolute">
<object id="chainFull" class="chain absolute" title="chain" data="assets/images/welcome/chain/chain_full.svg" type="image/svg+xml"></object>
</div>
<style>
:global(.chain) {
    left: -6em;
    top: 0;
    height: 100%;
    width: calc(100% + 12em);
}
@media screen and (min-width: 1100px) {
    :global(.chain) {
        left: -16em;
        width: calc(100% + 36em);
        height: calc(100% + 7em)
    }
}
</style>