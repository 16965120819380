<script>
import Button from './basic/button.svelte';
import IconButton from './basic/icon-button.svelte';
import { onMount } from 'svelte'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
gsap.registerPlugin(ScrollTrigger);

onMount(() => {
    // const showAnim = gsap.from('#navbar', { 
    //     yPercent: -100,
    //     paused: true,
    //     duration: 0.2
    // }).progress(1);

    // ScrollTrigger.create({
    //     start: "top top",
    //     end: 99999,
    //     onUpdate: (self) => {
    //         self.direction === -1 ? showAnim.play() : showAnim.reverse()
    //     }
    // });

    gsap.fromTo('body', {
            backgroundColor: '#FFFFFF',
        }, {
            backgroundColor: '#FCF8F2',
            duration: 1.5,
        })
});
</script>

<nav id="navbar" class="nav visible px-5">
    <div class="left-side flex flex-row justify-center items-center">
        <!-- <p class="font-futura text-lg leading-normal">Slavery Footprint.org</p> -->
        <Button 
            solid={true}
            url={`${location.protocol}//${location.host}/survey`}
        >
            <span class="highlight uppercase">Take</span>
            <span class="normal-case mx-1 text-white" >SlaveryFootprint.org</span>
            <span  class="highlight uppercase">Survey</span>
        </Button>
        <p style="font-size: 8px;" class="font-chronicaBook leading-normal mx-3"><span class="hidden md:inline">POWERED </span>BY</p>
        <a target='_blank' href="https://www.frdm.co/"><object class='pointer-events-none' title="FRDM Logo" data="assets/images/logo-black.svg" type="image/svg+xml"></object></a>
    </div>
    <div class="hidden right-side social-media md:flex flex-row justify-end items-center gap-2">
        <IconButton
            dark={false}
            size={'small'}
            iconName={'facebook'}
            url={'https://wwww.facebook.com/getfrdm/'}
        />
        <IconButton
            dark={false}
            size={'small'}
            iconName={'twitter'}
            url={'https://www.twitter.com/getfrdm'}
        />
        <IconButton
            dark={false}
            size={'small'}
            iconName={'instagram'}
            url={'https://www.instagram.com/getfrdm/'}
        />
        <IconButton
            dark={false}
            size={'small'}
            iconName={'linkedin'}
            url={'https://www.linkedin.com/company/frdmco'}
        />
    </div>
</nav>

<style>
nav {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top:0;

    width: 100vw;
    height: 54px;
    padding: 0 20px;
    margin: 0 auto;

    background-color: #DAE151;
    z-index: 100;
    user-select: none;
    transform: translate(0,calc(-100% - 1rem));
    transition: transform 0.2s;
}
nav.visible {
    transform: none;
}
object {
    width:73px;
    height: auto;
    object-fit: cover;
    padding-top:4px;
}
.highlight {
    color: #DAE451;
}
</style>
